/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 64px;
  left: 0;
  height: calc(100% - 64px);
  width: 240px;
  background-color: var(--primary-background);
  overflow-y: auto;
  transition: width 0.3s ease;
  z-index: 999;
  padding: 5px;
  border-right: 0.5px solid black;
}

.sidebar.collapsed {
  width: 35px;
  text-align: center;
}

.country-item,
.sidebar-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: var(--text-color);
  font-size: 16px;
  text-decoration: none !important;
  font-weight: 600 !important;
}

.sidebar-menu-item span {
  margin-left: 10px;
}

.sidebar.collapsed .sidebar-menu-item {
  justify-content: center;
}

.sidebar.collapsed .sidebar-menu-item span {
  display: none;
}

.sidebar-menu-item:hover {
  background-color: var(--hover-background);
}

.sidebar-menu-link {
  text-decoration: none !important;
  color: inherit;
}

.sidebar-menu-link:visited {
  text-decoration: none !important;
  color: inherit;
}

.sidebar-menu-link:hover {
  text-decoration: none !important;
  color: inherit;
}

.sidebar-items {
  margin-top: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
}

.section-divider {
  border: none;
  height: 2px;
  background-color: #333;
  margin: 10px auto;
}

.popular-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-section {
  color: var(--secondary-text-color);
  font-size: 10px;
  margin-left: 8px;
}

.collapsed-header {
  display: none;
  font-size: 20px;
  color: var(--secondary-text-color);
  text-align: center;
  width: 100%;
}

.sidebar.collapsed .collapsed-header {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
}

.sidebar-items {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  transition: max-height 0.3s ease;
}

.sidebar-items::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.sidebar-items {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.country-item:hover {
  background-color: var(--hover-background);
}

.flag-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.browsetext-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  width: auto;
  transition: width 0.5s ease, opacity 0.5s ease;
}

.country-name,
.region-name {
  margin: 0;
  padding: 0;
  text-transform: none;
  line-height: 1;
  font-weight: 600 !important;
  font-size: 16px;
}

.country-name {
  color: rgb(255, 255, 255) !important;
}

.region-name {
  color: rgb(179, 177, 177) !important;
}

.region-name {
  font-size: 13px;
  color: #ccc;
  font-weight: 500;
}

.sidebar.collapsed .browsetext-container {
  opacity: 0;
  visibility: hidden;
  width: 0;
  transition: width 0.5s ease, opacity 0.5s ease, visibility 0s 0.5s;
  display: none;
}

.sidebar.collapsed .country-item {
  justify-content: center;
  max-width: 50px;
}

.sidebar.collapsed .flag-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 10px;
}
.alert-icon-container {
  position: relative;
  left: 70px;
  top: 17%; 
  transform: translateY(-15%);
  font-size: 20px;
  color: red;
}

.sidebar.collapsed .alert-icon-container {
  visibility: hidden;
}
.sidebarFooter {
  position: absolute;
  bottom: 15px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  padding: 10px 15px;
  box-sizing: border-box;
  text-align: center;
  transition: opacity 0.3s ease;
}

.footerLink {
  color: #68dfdfbb;
  text-decoration: none;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.footerLink:hover {
  color: #0786c5;
  text-decoration: none;
}

.footerIcon {
  font-size: 25px;
}

.sidebar.collapsed .footerIcon {
  margin-left: -5px !important;
}

.skeletonloader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 95%;
  border-radius: 10px;
  margin: 0 auto;
}

.skeletonHeader,
.skeletonItem {
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
}

.skeletonHeader {
  width: 60%;
  height: 20px;
  margin-bottom: 10px;
}

.skeletonItem {
  width: 100%;
  height: 40px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}